import * as React from "react"
import { Container, Row,Col } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/css/NewsDetail.scss"
import { Link } from "gatsby"
import { AiFillLinkedin } from "react-icons/ai"
import { FaTwitter } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { FiArrowLeft } from "react-icons/fi"
import Gif01 from "../images/press-1.gif"
import Gif02 from "../images/press-2.gif"

const EdgeqPressKit3 = () => (
  <Layout>
    <Seo title="EdgeQ | Edgeq Press Kit" />

    <section className="News-detail">
      <Container>
        <Row>
          <div className="detail-head">
            <Link to="/media/#August" className="back-btn">
              <FiArrowLeft /> Company Announcements
            </Link>

            <h2>
              EdgeQ Showcases and Samples 5G Small Cell and O-RAN PCIe
              Accelerator Card
            </h2>
          </div>

          <div className="detail-para">
            <p className="text-left">
              <i>
                EdgeQ Exhibits World’s First Software Defined Elastic Silicon
                Platform Running 4G and 5G for Both All-in-One Small Cells and
                O-RAN Inline Layer 1 PCIe Accelerator card{" "}
              </i>
            </p>

            <div className="ajit-social">
              <ul className="social-icons">
                <li>
                  <a
                    aria-label="Save"
                    href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillLinkedin />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="Save"
                    href="https://twitter.com/edgeq_inc?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="Save"
                    href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube />
                  </a>
                </li>
              </ul>
            </div>
            <p>
              <b>SANTA CLARA, CA</b> – June 15, 2022 –{" "}
              <Link to="/">EdgeQ Inc</Link>, a leader in 5G wireless
              infrastructure, showcased and sampled a massively integrated,
              all-in-one, 4G+5G small cell and a highly programmable O-RAN PCIe
              acceleration card. The announcement comes at a time when EdgeQ is
              sampling and entering trials with worldwide OEMs and operators on
              their ground breaking 5G Base Station-on-a-Chip technology.
            </p>

            <p>
              The highly scalable, flexibly adaptive EdgeQ platform targets both
              gnB small cells and O-RAN applications in a completely softwarized
              manner. By compacting 4G, 5G, and compute into a single-chip,
              EdgeQ showcased an all-in-one small cell with fully integrated
              Radio Unit (RU), Distributed Unit (DU), and Central Unit (CU); L1,
              L2, L3 stacks; and fully inclusive ORAN splits. The scalable
              architecture packs unprecedented throughput performance across a
              large set of concurrent users, but all within a compact power
              envelop enabling Power-Over-Ethernet.
            </p>
            <Row className="align-items-center">
              <Col md={4}>
                  <img src={Gif01}/>
              </Col>  
              <Col md={8}>
                  <img src={Gif02}/>
              </Col> 

            </Row>

            <p>
              The same EdgeQ platform can also be refactored into a
              multi-carrier, multi-user, massive MIMO DU base station with L1
              inline acceleration. By simply uploading O-RAN Distributed Unit
              firmware, EdgeQ showcased both 4G and 5G Physical Layer running
              across an eCPRI interface but now with O-RAN splits in a
              multi-user environment.
            </p>
            <p>
              EdgeQ’s platform provides both 4G+5G in a single silicon unit,
              which is an unsolved pain point in today’s industry,” said Stephen
              Sek, Chief of Technology, US & EU Technology Centers, Wistron
              NeWeb Corporation (WNC). “By coupling low power with massive 5G+4G
              PHY integration and L2+L3 support, EdgeQ’s value statement is
              disruptively compelling for private and public 5G networks alike.”
            </p>

            <p>
              “These two landmark demonstrations show how we address the many
              pluralities of 5G. For small cells, the 5G network can be
              reconstituted as an all-in-one 5G gNB, complete with our field
              deployable 4G/5G PHY and integrated L2+L3 in a single silicon. We
              can leverage that exact silicon to also run a fully compliant
              O-RAN Distributed Unit PCIe card interoperating with standard
              O-RAN based splits. Having the gNB and PCIe accelerator card
              predicated on the same design platform greatly facilitates
              deployment ease as well as overall integration into our customers’
              networks,” said Adil Kidwai, Head of Product Management at EdgeQ,
              “We have imagined a highly flexible, softwarized 5G platform that
              is integrative, low power, and remarkably disruptive.”
            </p>

            <p>
              EdgeQ has also announced that they are sampling the gNB solution
              and PCIe card as they enter into the revenue phase of the company.
              <br />
              <br />
              To learn more about how EdgeQ is pioneering the 5G infrastructure
              market, please visit www.edgeq.io.
            </p>
            <br />
            <b>About EdgeQ</b>
            <br />
            
            <p>
              EdgeQ is a leading innovator in 5G systems-on-a-chip. The company
              is headquartered in Santa Clara, CA, with offices in San Diego, CA
              and Bangalore, India. Led by executives from Qualcomm, Intel, and
              Broadcom, EdgeQ is pioneering a converged connectivity and AI that
              is fully software-customizable and programmable. The company is
              backed by leading investors, including Threshold Ventures, Fusion
              Fund and AME Cloud Ventures, among others. To learn more about
              EdgeQ, visit <Link to="/">www.edgeq.io</Link>
            </p>
          </div>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default EdgeqPressKit3
